import React, { useEffect, useState } from 'react'
import { BiMessageError } from 'react-icons/bi'
import { GoGraph, GoPerson } from 'react-icons/go'
import { PiHouse } from 'react-icons/pi'
import { RiLoader2Fill } from 'react-icons/ri'
import { RxExit } from 'react-icons/rx'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { capitalize } from './actions/CommonActions'

const Root = () => {
  const location = useLocation();
  const isAssetsPage = location.pathname.startsWith("/assets");
  const [userLoginDetails, setUserLoginDetails] = useState(null)
  const navigate = useNavigate()

    useEffect(() => {
        const initiate = async () => {
          const userToken = await localStorage.getItem("token")
          if(!userToken) {
            await localStorage.clear()
            navigate("/")
          }
        const getUserdata = await localStorage.getItem("userdata")
        setUserLoginDetails(JSON.parse(getUserdata));
        }
        initiate()
    },[])

  const handleLogout = async() => {
    await localStorage.clear();
    window.location.href = "/";
  }

  const maximumLength = (text) => {
    // return text.length > 20 ? text.substring(0, 20) + "..." : text
  }

  return (
    <div className='d-flex w-100'>
        <div className='d-flex flex-column px-4 py-4 justify-content-between' style={{width: "300px", minHeight: "100vh", borderRight: "1px solid #EAECF0"}}>
            <div>
            <img src={require('../assets/Sanlamlogo.png')} width="160px" height="25px" />
            <NavLink to="/dashboard" className="d-flex text-decoration-none align-items-center rounded" style={({ isActive }) => ({ marginTop: "40px", color:  isActive ? "white": "#818181", backgroundColor: isActive ? "#007DCC" : "transparent", padding: "10px 15px"})}><PiHouse size={26} /> <p style={{fontSize: "18px", marginLeft: "10px", marginBottom: "0px", fontWeight: "500"}}>Dashboard</p></NavLink>
            <NavLink to="/assets" className="d-flex text-decoration-none align-items-center rounded" style={({ isActive, location }) =>{ 
               return { marginTop: "10px", color:  isAssetsPage  ? "white": "#818181", backgroundColor: isAssetsPage  ? "#007DCC" : "transparent", padding: "10px 15px"}}}
               ><RiLoader2Fill size={26} /> <p style={{fontSize: "18px", marginLeft: "10px", marginBottom: "0px", fontWeight: "500"}}>Assets</p></NavLink>
            {/* <NavLink to="/reports" className="d-flex text-decoration-none align-items-center rounded" style={({ isActive }) => ({ marginTop: "10px", color:  isActive ? "white": "#818181", backgroundColor: isActive ? "#007DCC" : "transparent", padding: "10px 15px"})}><GoGraph size={26} /> <p style={{fontSize: "18px", marginLeft: "10px", marginBottom: "0px", fontWeight: "500"}}>Report</p></NavLink> */}
            {userLoginDetails?.role  == "admin" && <NavLink to="/users" className="d-flex text-decoration-none align-items-center rounded" style={({ isActive }) => ({ marginTop: "10px", color:  isActive ? "white": "#818181", backgroundColor: isActive ? "#007DCC" : "transparent", padding: "10px 15px"})}><GoPerson size={26} /> <p style={{fontSize: "18px", marginLeft: "10px", marginBottom: "0px", fontWeight: "500"}}>Admin</p></NavLink>}
            {/* <NavLink to="/help" className="d-flex text-decoration-none align-items-center rounded" style={({ isActive }) => ({ marginTop: "10px", color:  isActive ? "white": "#818181", backgroundColor: isActive ? "#007DCC" : "transparent", padding: "10px 15px"})}><BiMessageError size={26} /> <p style={{fontSize: "18px", marginLeft: "10px", marginBottom: "0px", fontWeight: "500"}}>Help and support</p></NavLink> */}
            </div>
            <div>
                
            <button onClick={handleLogout} className="d-flex text-decoration-none align-items-center rounded" style={{marginTop: "10px", color: "#DA1010", backgroundColor: "transparent", padding: "20px 15px", cursor: "pointer", border: "none", borderBottom: "none"}}><RxExit size={26} /> <p style={{fontSize: "18px", marginLeft: "10px", marginBottom: "0px", fontWeight: "500"}}>Log out</p></button>
            <div style={{ borderBottom: "1px solid #EAECF0"}}></div>
                <div className='d-flex mt-4'>
                <div style={{backgroundColor: "#007DCC", padding: "10px", borderRadius: "50%"}}>
                    <p style={{ marginBottom: "0px", color: "white", fontWeight: "500", fontSize: "18px"}}>{capitalize(userLoginDetails?.firstName?.slice(0,1))}{capitalize(userLoginDetails?.lastName?.slice(0,1))}</p>
                    </div>
                    <div style={{marginLeft: "10px"}}>
                        <p style={{fontWeight: "500", fontSize: "18px", color: "#344054", marginBottom: "0px"}}>{capitalize(userLoginDetails?.firstName)} {capitalize(userLoginDetails?.lastName)}</p>
                        <p style={{ fontSize: "14px", color: "#344054", marginBottom: "0px"}}>{userLoginDetails?.email}</p>
                    </div>
                    </div>
            </div>
        </div>
        <div style={{width: "100%"}}>
        <Outlet />
        </div>
    </div>
  )
}

export default Root
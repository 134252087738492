import moment from 'moment'
import React, { useState } from 'react'
import { IoChevronDownSharp, IoChevronUpOutline } from 'react-icons/io5'
import { MdAdd, MdCheckCircle } from 'react-icons/md'

const AttachmentAsset = ({gottenData, handleFileChange, handleDeleteAttachment }) => {
  const [fileKey, setFileKey] = useState(Date.now()); // Unique key for file input

  return (
    <>
          <div style={{marginBottom: "0px", backgroundColor: "#F2F4F7", padding: "10px", display: "flex", justifyContent: "space-between"}}>
          <p style={{marginBottom: "0px", fontWeight: "500", fontSize: "23px"}}>Attachment</p>
          <label
                htmlFor='imgFile'
              className="rounded pt-2 px-3"
              style={{
                color: "#007DCC",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontWeight: "bold"
              }}
            >
              <MdAdd size={20} /> Upload
            </label>
            <input id="imgFile" key={fileKey} onChange={(event)=>{
              handleFileChange(event)
              setFileKey(Date.now()); // Reset input field by updating key
            }} type="file" style={{display: "none"}} />
          </div>
          <div style={{border: "1px solid #EAECF0", padding: "10px", minHeight: "70vh"}}>
            {gottenData?.attachment?.length < 1 ? <div style={{display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column", height: "70vh"}}>
                <img src={require('../assets/cloud-connection.png')} alt="attachment" style={{width: "100px", height: "100px"}} />
                <p style={{color: "black", fontWeight: "500"}}>No attachments uploaded yet</p>
                <label
                htmlFor='imgFile'
              className="rounded py-2 px-3"
              style={{
                color: "#007DCC",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontWeight: "bold"
              }}
            >
              <MdAdd size={20} /> Upload
            </label>
            <p style={{fontSize: "14px", color: "#98A2B3", fontStyle: "italic"}}>(not more than 5mb)</p>
                </div>
                : 
                <div>
                 {gottenData?.attachment?.map((each, index) => <div key={index} style={{display: "flex", flexDirection: "column", marginTop: "20px", width: "500px"}}>
                    <div  style={{border: "2px dashed #667085", padding: "10px 20px", borderRadius: "5px", backgroundColor: "#F2F4F7", display: "flex", alignItems: "center"}}>
                        <img src={require('../assets/fileAttachment.png')} alt="attachment" style={{width: "40px", height: "40px"}} />
                        <div style={{marginLeft: "20px", display: "flex", flex: 1, flexDirection: "column"}}>
                          <p style={{fontSize: "20px", fontWeight: "500", marginBottom: "0px"}}>{each?.name}</p>
                          {/* <p style={{color: "#667085", marginBottom: "0px"}}>100% uploaded</p> */}
                        </div>
                        <MdCheckCircle color="#007DCC" size={25} />
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "5px"}}>
                      <a href={each?.attachment_url}  target="_blank" style={{color: "#007DCC", marginBottom: "0px", fontWeight: "500", cursor: "pointer", textDecoration: "none"}}>Preview</a>
                      <p onClick={()=> handleDeleteAttachment(each?.id)} style={{color: "#F04438", marginBottom: "0px", fontWeight: "500", cursor: "pointer"}}>Delete</p>
                    </div>
                  </div>)}
                  </div>}
          </div>
          </>
  )
}

export default AttachmentAsset
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { capitalize, notify } from "../components/actions/CommonActions";
import {
  addAsset,
  downloadSearchApi,
  getAllAssets,
  getAllAssetsType,
  getAllOrganizations,
  getLocation,
  getLocationApi,
  getSingleAsset,
  inActiveAssetApi,
  searchAssetApi,
} from "../services";
import LoadingModal from "../components/modals/LoadingModal";
import AddAssetsModal from "../components/modals/AddAssetsModal";
import { all } from "axios";
import { useNavigate } from "react-router-dom";
import InactiveAssetModal from "../components/modals/InactiveAssetModal";
import { GoDownload } from "react-icons/go";

const Assets = () => {
  const [allAssets, setAllAssets] = React.useState([]);
  const [indexPressed, setIndexPressed] = useState(null);
  const [show, setShow] = useState(false);
  const [assignUser, setAssignUser] = useState(null);
  const [pageCount, setPageCount] = useState(0); // For total pages
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [isLoading, setIsLoading] = useState(false);
  const [allAssetsType, setAllAssetsType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAssest, setSelectedAsset] = useState(1);
  const [allOrganizations, setAllOrganizations] = useState(null);
  const [allUserLocations, setAllUserLocation] = useState(null);
  const [addAssetModal, setAddAssetModal] = useState(false);  
  const [step, setStep] = useState(1)
  const [data, setData] = useState({
    description: "",
    asset_type: 0,
    asset_type_name: "",
    asset_tag: "",
  })
  const [assigned, setAssigned] = useState([{reg_id: "", full_name: "", staff_location: "", state: "", status: true}]);
  const [dynamicInput, setDynamicInput] = useState([])
  const [userLoginDetails, setUserLoginDetails] = useState(null)
  const [searchText, setSearchText] = useState("");
  const [inactiveModal, setInactiveModal] = useState(false);
  const [inactivateId, setInactivateId] = useState(null);
  const navigate = useNavigate();

  // console.log(" assign user  ", assignUser);
  

  useEffect(() => {
    const initiate = async () => {
    const getUserdata = await localStorage.getItem("userdata")
    setUserLoginDetails(JSON.parse(getUserdata));
    }
    initiate()
},[])

  const initiate = async ( limit, page, selectedAssest) => {
    setIsLoading(true);
    await getAllAssets(limit, page, selectedAssest)
      .then(async (res) => {
        console.log("anon res ", res);
        setAllAssets(res?.paginated?.data);
        setPageCount(res?.paginated?.totalPages);
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoading(false);
  };

  const getAllLocations = async () => {
    await getLocationApi()
    .then((res) => {
      console.log("location info ", res);
      
    })
    .catch((err) => {
      // console.log('err res anon ', err);
      notify("danger", "OOPS", err?.response?.data?.message);
    });
  };

  const AllAssetsType = async (page, limit) => {
    setIsLoading(true);
    await getAllAssetsType()
      .then(async (res) => {
        // console.log("assets res ", res);
        setAllAssetsType(res);
        // setAllUsers(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoading(false);
  };

  const AllOrganizations = async (page, limit) => {
    setIsLoading(true);
    await getAllOrganizations()
      .then(async (res) => {
        // console.log("organizations res ", res);
        setAllOrganizations(res?.data);
        // setAllUsers(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoading(false);
  };

  const AllLocations = async (page, limit) => {
    setIsLoading(true);
    await getLocation()
      .then(async (res) => {
        console.log("organizations res ", res);
        setAllUserLocation(res?.data);
        // setAllUsers(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoading(false);
  };

  const downloadPDF = async () => {
    try {
      let sentdata = {
        searchTerm: searchText,
        exportToExcel: true
      }
      const response = await downloadSearchApi(sentdata);
  
      // Create a blob from the response
    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "report.xlsx"); // Change filename if needed
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      setLoading(true);
      await AllAssetsType();
      await AllOrganizations();
      AllLocations()
      await initiate(10, 1, selectedAssest);
      // await getAllLocations();
      setLoading(false);
    };
    getInfo();
  }, []);

  useEffect(() => {
    const getInfo = async () => {
      setIsLoading(true);
      await initiate(10, 1, selectedAssest);
      setIsLoading(false);
    };
    getInfo();
  }, [selectedAssest]);

  const handlePageClick = async (data) => {
    setIsLoading(true);
    let selectedPage = data.selected + 1; // react-paginate uses 0-based index, so add 1
    setCurrentPage(selectedPage); // Update current page state
    await initiate(10, selectedPage, selectedAssest); // Fetch the new page data
    setIsLoading(false);
  };

  const handleViewDetails = async (id) => {
    // console.log("id ", indexPressed);
    
    navigate(`/assets/details/${indexPressed}`)
    // setIsLoading(true);
    // await getSingleAsset(indexPressed)
    //   .then(async (res) => {
    //     console.log("single asset res ", res);
    //     // setAllAssets(res?.paginated?.data);
    //     // notify("success", "Profile updated", res?.message);
    //   })
    //   .catch((err) => {
    //     // console.log('err res anon ', err);
    //     notify("danger", "OOPS", err?.response?.data?.message);
    //   });
    // setIsLoading(false);
  }

  const handleChange = (e) =>{
    const {name, value} = e.target;
    setData(prev => ({...data, [name]: value}))
  }

  const handleAssignChange = (i, e) =>{
    let newAssigned = [...assigned];
    newAssigned[i][e.target.name] = e.target.value;
    setAssigned(newAssigned)
  }

  const addAssignedField = () =>{
    setAssigned([...assigned, {reg_id: "", full_name: "", staff_location: "", state: ""}])
  }

  const removeAssignedField = (i) =>{
    let newAssigned = [...assigned];
    newAssigned.splice(i, 1)
    setAssigned(newAssigned)
  }

  const handleDynamicInputChange = (i, e) =>{
    let newAssigned = [...dynamicInput];
    newAssigned[i][e.target.name] = e.target.value;
    setDynamicInput(newAssigned)
  }

  const addDynamicInputField = () =>{
    setDynamicInput([...dynamicInput, {name: "", value: ""}])
  }

  const removeDynamicInputField = (i) =>{
    let newAssigned = [...dynamicInput];
    newAssigned.splice(i, 1)
    setDynamicInput(newAssigned)
  }

  const onHide = () =>{
    setAddAssetModal(false)
    setStep(1)
    setAssigned([{reg_id: "", full_name: "", staff_location: "", state: ""}])
    setDynamicInput([{name: "", value: ""}])
    setData({
      description: "",
     asset_type: 0,
     asset_type_name: "",
     asset_tag: "",
   })
 }

 const handleSubmit = async (e) =>{
  e.preventDefault();
  
  const itemId = allAssetsType?.data?.find(item=> item?.name == data?.asset_type_name)
  data.asset_type = itemId?.id
  const transformedObject = dynamicInput.reduce((acc, item) => {
    if (item.name && item.value) {  // Only add if both name and value are non-empty
      acc[item.name] = item.value;
    }
    return acc;
  }, {});
  let sentData = {...data, ...transformedObject}
  setAddAssetModal(false)
  
  if (assignUser === "no") {
    sentData.assigned_to = [];
  } else {
    if (assigned[0]?.full_name === "" && !assigned[0]?.reg_id) {
      sentData.assigned_to = [];
    } else {
      sentData.assigned_to = assigned;
    }
  }
  // console.log("data sent ", sentData);
  setIsLoading(true);

  await addAsset(sentData)
  .then(async (res) => {
    setIsLoading(false);
    // console.log("add asset res ", res);
    setStep(1)
    setAssigned([{reg_id: "", full_name: "", staff_location: "", state: ""}])
    setDynamicInput([{name: "", value: ""}])
    setData({
      description: "",
     asset_type: 0,
     asset_type_name: "",
     asset_tag: "",
   })
   notify("success", "Asset added", res?.message);
   await initiate(10, 1, selectedAssest);
  })
  .catch((err) => {
    notify("danger", "OOPS", err?.response?.data?.message);
  });

  setIsLoading(false);
 }

 const handleSubmitSearch = async() =>{
  console.log("search text ", searchText);
  setIsLoading(true);
  if(searchText == ""){
    await initiate(10, 1, selectedAssest);
  }
  else{
  await searchAssetApi(searchText)
  .then((res)=>{
    console.log("search res ", res);
    setAllAssets(res?.data || []);
  })
  .catch((err)=>{
    console.log("search err ", err);
    
  })
}
  setIsLoading(false);
 }

 const handleInactiveAsset = async() =>{
  setInactiveModal(false)
  setIsLoading(true);
  await inActiveAssetApi(inactivateId)
  .then(async (res)=>{
    // console.log("search res ", res);
    notify("success", "Successful", "Asset marked as inactive");
    await initiate(10, 1, selectedAssest);
  })
  .catch((err)=>{
    console.log("search err ", err);notify("danger", "OOPS", err?.response?.data?.message);
    
  })
  setIsLoading(false);
 }


  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="loader">
            Loading
            <span></span>
          </div>
        </div>
      ) : (
        <div className="px-3 py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "32px",
                  marginBottom: "0px",
                }}
              >
                Assets
              </p>
              <p style={{ color: "#475467", fontSize: "18px" }}>
                Welcome {capitalize(userLoginDetails?.firstName)},
              </p>
            </div>
            <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
                cursor: "pointer"
              }}
              onClick={() => setAddAssetModal(true)}
            >
              <FiPlus size={20} /> Add asset
            </button>
          </div>
          {allAssets?.length > 0 && <div className="d-flex justify-content-between align-items-center mt-3">
            <div style={{display: "flex"}}>
            <div
              style={{
                border: "1px solid #EAECF0",
                padding: "10px",
                width: "400px",
                borderRadius: "5px",
                display: "flex",
                marginRight: "10px"
              }}
            >
              <CiSearch style={{ color: "#414141" }} size="25px" />
              <input
                placeholder="Search for any asset by model number, name etc"
                type="text"
                name="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  border: "none",
                  display: "flex",
                  flex: 1,
                  marginLeft: "10px",
                }}
              />
            </div>
            <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
                cursor: "pointer"
              }}
              onClick={handleSubmitSearch}
            >
             Search
            </button>
            {searchText && <div
              className="rounded py-2 px-3"
              style={{
                borderColor: "#007DCC",
                border: "none",
                backgroundColor: "white",
                cursor: "pointer",
                marginLeft: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
              onClick={downloadPDF}
            >
              <GoDownload style={{marginRight: "5px"}} />
             Export
            </div>}
            </div>
            <select
              style={{
                border: "1px solid #EAECF0",
                padding: "10px",
                borderRadius: "5px",
              }}
              onChange={(e)=>{
                setSelectedAsset(e.target.value)
              }}
            >
              {allAssetsType?.data?.map((each, index) => (
                <option key={index} value={each?.id}>
                  {each?.name}
                </option>
              ))}
            </select>
          </div>}
          {allAssets?.length < 1 ? 
          <div style={{display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column", height: "70vh"}}>
          <img src={require('../assets/cloud-connection.png')} alt="attachment" style={{width: "100px", height: "100px"}} />
          <p style={{color: "black", fontWeight: "500"}}>No asset uploaded yet</p>
          
          </div>
          :
          <div
            style={{
              borderRadius: "5px",
              marginTop: "30px",
            }}
          >
            <table style={{ width: "100%", overflowX: "scroll" }}>
              <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid #f1f1f1",
                    backgroundColor: "#F7F7F7",
                  }}
                >
                  {/* Table header cells */}
                  {/* Modify according to your data structure */}
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    NAME
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    DEVICE
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    ASSET TAG NO
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    PURCHASE DATE
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    AGE ANALYSIS
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    LIFECYCLE
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    STATUS
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {allAssets?.map((item, index) => {

                  return (
                    <tr
                      key={index}
                      style={{ backgroundColor: "white", width: "100%" }}
                    >
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase"
                        }}
                      >
                        {item?.description}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase"
                        }}
                      >
                        {item?.device_type?.name}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase"
                        }}
                      >
                        {item?.assetTag}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase"
                        }}
                      >
                        {item?.purchaseDate? moment(item?.purchaseDate).format("MMM DD, YYYY"): ""}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase"
                        }}
                      >
                        {item?.ageAnalysis}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase",
                          color:
                            item?.lifeCycle == "assigned"
                              ? "#067647"
                              : item?.lifeCycle == "available"
                              ? "#007DCC"
                              : item?.lifeCycle == "lost/stolen"
                              ? "#E02D3C"
                              : "black",
                        }}
                      >
                        {capitalize(item?.lifeCycle)}
                      </td>

                      <td>
                        <span
                          style={{
                            padding: "5px",
                            borderRadius: "10px",
                            color:
                              item?.status == "active"
                                ? "green"
                                : item?.status == "inactive"
                                ? "red"
                                : "black",
                            fontWeight: "500",
                          }}
                        >
                          {capitalize(item?.status)}
                        </span>
                      </td>
                      {/* <td><span style={{padding: "5px", backgroundColor: item?.status == "active"? "#E7F6EC" : item?.status == "invited"? "#E3EFFC" : "#FBEAE9", borderRadius: "10px", color: item?.status == "active"? "#036B26": item?.status == "invited"? "#04326B": "#9E0A05", fontWeight: "500"}}>{capitalize(item?.status)}</span></td> */}
                      <td
                        style={{ position: "relative" }}
                        onClick={() => {
                          setIndexPressed(item?.id)
                          setShow(!show);
                        }}
                      >
                        <span
                          style={{
                            border: "1px solid #E4E7EC",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            zIndex: "5",
                          }}
                        >
                          <HiDotsVertical />
                        </span>
                        {item?.id == indexPressed && show && <div style={{position: "absolute", padding: "5px 10px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", zIndex: "10", backgroundColor: "white", left: "-100px", width: "150px"}}>
                         <p onClick={handleViewDetails} style={{fontSize: "14px", padding: "5px", cursor: "pointer", textAlign: "left", marginBottom: "0px"}}>View details</p>
                         <p onClick={()=> {
                          setInactiveModal(true)
                          setInactivateId(item?.id)
                         }} style={{fontSize: "14px", padding: "5px", cursor: "pointer", textAlign: "left", marginBottom: "0px"}}>Mark as inactive</p>
                      </div>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount} // Total pages from API
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={currentPage - 1}
            />
          </div>}
          <LoadingModal show={isLoading} />
          <AddAssetsModal show={addAssetModal} allUserLocations={allUserLocations} allAssetsType={allAssetsType} onHide={onHide} step={step} setStep={setStep} handleChange={handleChange} data={data} handleAssignChange={handleAssignChange} addAssignedField={addAssignedField} removeAssignedField={removeAssignedField} assigned={assigned} handleSubmit={handleSubmit} dynamicInput={dynamicInput} handleDynamicInputChange={handleDynamicInputChange} addDynamicInputField={addDynamicInputField} removeDynamicInputField={removeDynamicInputField} assignUser={assignUser} setAssignUser={setAssignUser} />
          <InactiveAssetModal show={inactiveModal} onHide={()=> setInactiveModal(false)} handleSubmit={handleInactiveAsset} />
        </div>
      )}
    </>
  );
};

export default Assets;
